import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "gatsby"
import logow from '../../images/logo.svg';
import colorlogo from '../../images/logo.svg';
import { CANDIDATE_PAGE_URL, EMPLOYERS_PAGE_URL } from "@Components/common/site/constants";
import OutsideAlerter from "../OutSideClick"
import GetURL from '@Components/common/site/get-url';
import Helmet from 'react-helmet'
import CommonSearchHeader from '@Components/predictive-search/common-search-header'
import { WishlistCount } from "@Components/myaccount/wishlistcount"

import './Header.scss';
import { useStaticQuery, graphql } from "gatsby";
// Header component
const Header = (props) => {
  const data = useStaticQuery(graphql`
  query{
    glstrapi {
      contact {
        Address
        Email
        Facebook_Link
        Instagram_Link
        Linkedin_Link
        Phone
        Twitter_Link
      }
      menuBurgers(publicationState: LIVE, where: {Publish: true}, sort: "Sort") {
        Sub_Menus {
          Label
          URL {
            Alias
          }
        }
        Menu_Parent {
          Parent_Alias {
            Alias
          }
          Parent_Label
        }

      }
    }

  }
  `);

  var footer_data = data.glstrapi.contact;
  let hamburger = useRef(null); // creating hamburger ref
  let primaryHeader = useRef(null); // Creating ref for hamburger
  const [scroll, setScroll] = useState(false);
  const [banscroll, bansetScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(props.nobanner ? window.scrollY > 100 : window.scrollY > 450)
      bansetScroll(window.scrollY > 60)
    })
  }, [])


  // adding and removing class from main nav
  const handleHamBurgerClick = () => {
    SetmenuIndex(null)
    if (Array.from(hamburger.current.classList).includes("open-navigation")) {
      primaryHeader.current.classList.remove("open-navigation");
      document.body.classList.remove('overflow-hidden');

    } else {
      primaryHeader.current.classList.add("open-navigation")
      document.body.classList.remove('overflow-hidden');

    }
    if (Array.from(hamburger.current.classList).includes("cross")) {
      hamburger.current.classList.remove("cross")
      primaryHeader.current.classList.remove("open-navigation")
      document.body.classList.remove('overflow-hidden');
    } else {
      hamburger.current.classList.add("cross")
      document.body.classList.add('overflow-hidden');
    }
  }

  // Out side click remove class
  const handleOutSideClick = e => {
    primaryHeader.current.classList.remove("open-navigation");
    let cross = document.getElementsByClassName("cross");
    if (cross && cross.length > 0) {
      setTimeout(() => { cross[0]?.classList?.remove("cross") }, 200)
      setTimeout(() => { document.body.classList.remove('overflow-hidden');
     }, 200)
    }
  }
  const [menuIndex, SetmenuIndex] = useState(null)
  // function for toggle between + and - icons in nav Menu
  const toggleNav = (e) => {
    if (e === menuIndex) {
      SetmenuIndex(null)

    }
    else {
      SetmenuIndex(e)

    }
    // try {
    //   let isClassFound = e.target.classList.contains("icon-minus")
    //   if (!isClassFound) {
    //     let dom = document.getElementsByClassName("icon-vector icon-minus")
    //     let domSub = document.getElementsByClassName("sub-menu parent")
    //     let subNav = document.getElementsByClassName("navchild")
    //     let childIcons = document.getElementsByClassName("childicon")
    //     for (let element of subNav) {
    //       element.style.display = "none"
    //     }
    //     // for (let element of domSub) {
    //     //   element.style.display = "none"
    //     // }
    //     for (let element of dom) {
    //       element.classList.remove("icon-minus")
    //     }
    //     for (let element of childIcons) {
    //       element.classList.remove("icon-minus")
    //       element.classList.add("icon-vector")
    //     }
    //     e.target.classList.add("icon-minus")
    //     // e.target.parentNode.nextSibling.style.display = "block"
    //     dom = null
    //   } else {
    //     e.target.classList.remove("icon-minus")
    //     let dom = document.getElementsByClassName("sub-menu parent")
    //     let subChild = document.getElementsByClassName("sub-menu child")
    //     for (let element of subChild) {
    //       element.style.display = "block"
    //     }

    //     for (let element of dom) {
    //       element.style.display = "none"
    //     }
    //     // e.target.parentNode.nextSibling.style.display = "none"
    //   }
    // } catch (e) {
    //   console.log("having some error in Toogle function", e)
    // }
  }
  // function for toggle + and - in sub navigation
  const toggleNavChild = e => {
    let isClassFound = e.target.classList.contains("icon-minus");
    let subNav = document.getElementsByClassName("navchild");
    let subMenu = document.getElementsByClassName("childicon");
    let subMenuChild = document.getElementsByClassName("navchild");
    if (!isClassFound) {
      for (let element of subNav) {
        element.style.display = "block"
      }
      for (let element of subMenuChild) {
        element.style.display = "none"
      }
      for (let element of subMenu) {
        element.classList.remove("icon-minus")
      }
      e.target.classList.add("icon-minus");
      e.target.parentNode.nextSibling.style.display = "block"
    } else {
      for (let element of subNav) {
        element.style.display = "none"
      }

      e.target.classList.remove("icon-minus")
    }
  }
  return (
    <div className={props.nobanner === true && scroll ? "jobdetails-header" : props.nobanner === true ? "jobdetails-header" : props.nobanner === false && scroll ? "jobdetails-header" : ""}>
      <header className={props.nobanner === true && scroll ? "static header fixed-header" : props.nobanner === true ? "static header" : props.nobanner === false && scroll ? "header fixed-header" : "header absolt"} ref={primaryHeader}>
        {/* <Helmet>
          <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=glfbsa6ueyjotpuqsf2otw" async="true"></script>
        </Helmet> */}

        <Container>
          <Row>
            <Col>
              <div className="header-wrapper">
                <div className={props.nobanner === true && scroll ? 'header-logo' : props.nobanner === true ? 'header-logo' : props.nobanner === false && scroll ? "header-logo" : 'header-logo logo--white'}>
                  {/* {props.nobanner === true && scroll ? 'true' : props.nobanner === true ? 'true' : props.nobanner === false && scroll ? "true" : 'false'} */}
                  {/* {props.nobanner === true ? 'true' : 'false'} */}
                  <Link to="/">
                    <picture>
                      {props.nobanner === true && scroll ? <source media="(min-width:768px)" srcSet={colorlogo} alt="banner" />

                        : props.nobanner === false && scroll ?
                          <source media="(min-width:768px)" srcSet={colorlogo} alt="banner" />
                          : props.nobanner === true ?
                          <source media="(min-width:768px)" srcSet={colorlogo} alt="banner" />
                          :
                          <source media="(min-width:768px)" srcSet={logow} alt="Logo" />

                      }
                      {props.nobanner === true && scroll ? <img src={colorlogo} alt="logo" /> :
                        props.nobanner === false && scroll ?
                          <img src={colorlogo} alt="logo" />
                          : props.nobanner === true ?
                          <img src={colorlogo} alt="logo" />
                          :
                          <img src={logow} alt="logo" />
                      }
                    </picture>
                  </Link>
                </div>
                <div className="list-wrapper">
                  <div className="header-menu d-none">
                    <ul className="menu-item">
                      <li>
                        <Link to={EMPLOYERS_PAGE_URL.alias}>
                          employers
                        </Link>
                      </li>
                      <li>
                        <Link to={CANDIDATE_PAGE_URL.alias}>
                          candidates
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <Col lg={1} xs={1} className="text-xl-right d-md-block wishlist-mobile"> */}
                <WishlistCount />
              {/* </Col> */}
                  <div className="humburger" ref={hamburger}
                    onClick={handleHamBurgerClick}>
                    <i className="icon-humburger">
                    </i>
                  </div>
                </div>
              </div>
              {/* <Form className="tab-form d-md-none opacity-hidden">
                <Form.Control type="Text" placeholder="Job title, position or location" />
                <Link href="#" className="search">
                  <i className="icon-search">
                  </i>
                </Link>
              </Form> */}


              {/* <div className="tab-content tablet d-none d-md-block">
              <Form className="tab-form">
                <Form.Control type="Text" placeholder="Technology" className="input-field technology-filed" />

                <Form.Control type="Text" placeholder="Arabian Ranches, Dubai" className="input-field" />
                <Link href="#" className="search">
                  <i className="icon-search">
                  </i>
                </Link>
              </Form>
            </div> */}

            <div className="tab-content02 d-none">
              {/* {banscroll && */}
              <CommonSearchHeader />
              {/* } */}
            </div>


            </Col>
          </Row>
        </Container>
        <div className="nav-wrap">
          {/* <nav className="navigation">
            <div className="hamburger-wrap">
              <button
                className="hamburger hamburger-cross"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </nav> */}
          <div className="nav-wrap">
            <OutsideAlerter 
            // handleSubRoutine={handleOutSideClick}
            >
              <nav className="navigation">
                <div className="hamburger-wrap">
                  <button
                    className="hamburger hamburger-cross "
                    onClick={handleHamBurgerClick}
                  >
                    <span></span>
                    <span></span>
                    <span></span>

                  </button>
                </div>
                <ul className="nav-item">
                  {data.glstrapi.menuBurgers?.map((item, i) => (
                    <li className={menuIndex === i ? "active": ""}>
                      {item?.Sub_Menus?.length > 0 ? 
                      <a href="javascript:void(0)" onClick={(e) => { toggleNav(i) }} onKeyDown={(e) => { toggleNav(i) }}>{item?.Menu_Parent?.Parent_Label}</a>
                      : 
                      <GetURL
                        // toggleNav={toggleNav}
                        label={item?.Menu_Parent?.Parent_Label} alias={item?.Menu_Parent?.Parent_Alias?.Alias}
                      // isplus={item?.Sub_Menus?.length > 0 ? true : false} 
                      />}
                      {item?.Sub_Menus?.length > 0 &&
                        <i className={menuIndex === i ? "icon-vector icon-minus" : "icon-vector"} onClick={(e) => { toggleNav(i) }} onKeyDown={(e) => { toggleNav(i) }} aria-label="buy"></i>
                      }
                      {menuIndex === i &&
                        <ul className="sub-menu parent" style={{ display: 'block' }}>
                          {
                            item?.Sub_Menus &&
                            item?.Sub_Menus.map((newitem, j) =>
                              <li>
                                <GetURL label={newitem.Label} alias={newitem.URL?.Alias} />

                              </li>
                            )}
                        </ul>
                      }
                    </li>
                  ))}
                </ul>

                <ul className="social-icon d-md-none">
                  {footer_data?.Facebook_Link &&
                    <Link href={footer_data?.Facebook_Link} target="_blank">
                      <i className="icon-fb"></i>
                    </Link>
                  }
                  {footer_data?.Linkedin_Link &&
                    <Link href={footer_data?.Linkedin_Link} target="_blank">
                      <i className="icon-linkedln"></i>
                    </Link>
                  }
                  {footer_data?.Instagram_Link &&
                    <Link href={footer_data?.Instagram_Link} target="_blank">
                      <i className="icon-insta"></i>

                    </Link>
                  }
                  {footer_data?.Twitter_Link &&
                    <Link href={footer_data?.Twitter_Link} target="_blank">
                      <i className="icon-twitter"></i>
                    </Link>
                  }

                </ul>
              </nav>

            </OutsideAlerter>
          </div>



        </div>
        <div className="fixed-overlay">

        </div>
      </header></div>)
};
export default Header;